/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule, getLocale } from '@libs/dash/core';
import { FeatureMapService } from '@libs/dash/guards';
import { PageAsideNavigationModule } from '@libs/dash/shared';
import { BreadcrumbService } from '@libs/shared/modules/breadcrumb';
import { PermissionsPipe } from "@libs/shared/modules/frontend-common";
import { LanguageCode, LANGUAGES, TranslateInitializer, TranslateModule } from '@libs/shared/modules/i18n';
import { appInitializerFactory } from './app-initializer-factory';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

const translationFiles = ['common', 'breadcrumb'];

const languages: LanguageCode[] = [LANGUAGES.EN, LANGUAGES.FR];

const PERMISSIONS_PIPE = {
	provide: PermissionsPipe,
	useValue: () => {
		return {transform: () => true};
	},
	multi: false,
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		CoreModule,
		AppRoutingModule,
		PageAsideNavigationModule,
		MatTableModule,
		MatExpansionModule,
		MatIconModule,
		TranslateModule.forRoot({ files: translationFiles, languages }),
	],
	bootstrap: [AppComponent],
	providers: [
		PERMISSIONS_PIPE,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [FeatureMapService, TranslateInitializer],
			multi: true,
		},
		{ provide: MAT_DATE_LOCALE, useValue: getLocale() },
		{ provide: LOCALE_ID, useValue: getLocale() },
		BreadcrumbService,
	],
})
export class AppModule {}
